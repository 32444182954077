<template>
  <div class="music-wrap">
    <template v-if="list.length > 0">
      <div class="music-card" v-for="item in list" :key="item.music_id">
        <div class="music-img" @click="handlePlayerMusic(item)">
          <img :src="item.logo" alt="">
          <div class="info">
            <p class="name">{{ item.name }}</p>
            <p class="bpm">BPM：{{ item.bpm }}</p>
          </div>
        </div>
        <p class="music-labels">{{ item.labels.map((i) => i.name).join('，') }}</p>
        <p class="music-time">{{ (item.time || item.duration) | formatTimeBySecond }}</p>
        <p v-if="target == 'collect'" class="music-time">{{ item.collect_at }}</p>
        <div class="music-op">
          <img :src="require(`@/assets/svg/operation/${item.is_collected === 0 ? 'collect' : 'collected'}.svg`)" alt=""
            @click="handleMusicCollect(item)">
          <img :src="require(`@/assets/svg/operation/share.svg`)" alt=""
            @click="handleShare('', item.object_id, item.type || 2, item.name)">
          <img :src="require(`@/assets/svg/operation/downLoad.svg`)" alt="" @click="handleDownload(item)">
          <img :src="require(`@/assets/svg/operation/cart.svg`)" alt="" @click="handleAddGoodsToCart(item, 2)">
        </div>
      </div>
    </template>
    <el-empty v-else />
    <!-- 分页器 -->
    <div class="vf-music-pagination" v-if="JSON.stringify(pageQuery) !== '{}'">
      <Pagination v-if="pageQuery.total > 0" :hiddenPage="true" position="center" :limit.sync="pageQuery.page_size / 1"
        :page.sync="pageQuery.page" @pagination="pagination" :total="pageQuery.total" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Buttons from '@/layout/mixins/Buttons';
import { getToken } from "@/utils/author";
export default {
  components: {
    Pagination
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: 'center'
    },
    pageQuery: {
      type: Object,
      default: () => { }
    },
    // 页面来源
    target: {
      type: String,
      default: ''
    }
  },
  mixins: [Buttons],
  methods: {
    pagination() {
      this.$emit('pagination')
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    async handleMusicCollect(item) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collected = item.is_collected === 0 ? 1 : 0
      await this.handleCollect(item.object_id, item.type || 2, {
        ...item,
        type: 2,
        labels: item.label
      }, false)
      // 收藏页面取消收藏后需重新拉取列表数据
      if (this.target === 'collect') {
        this.$emit('againGetList', 2)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.music-wrap {
  width: 100%;
  transform: translateX(-20px);

  .music-card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 60px 12px 20px;
    box-sizing: border-box;

    &:hover {
      background: #FFFFFF;
    }

    .music-img {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 70px;
        height: 70px;
        background: rgba(0, 0, 0, 0.2);
      }

      .info {
        margin-left: 12px;

        .name {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          margin-bottom: 8px;
          max-width: 12vw;
          min-width: 12vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .bpm {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 17px;
        }
      }
    }

    .music-labels,
    .music-time {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 17px;
    }

    .music-labels {
      width: 15vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .music-op {
      cursor: pointer;

      img {
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .vf-music-pagination {
    width: 100%;
    margin-top: 50px;
  }
}
</style>
