<template>
  <div class="video-wrap">
    <template v-if="list.length > 0">
      <div class="video-card" v-for="(data, idx) in list" :key="idx">
        <!-- <div class="video" @mouseover="handleMouseOver(data)" @mouseleave="handleMouseLeave(data)"
          @click="gotoDetail(data.music_id)">
          <img class="coverimg" :id="`coverImg_${data.object_id}`"
            :src="data.watermark_url + '?vframe/jpg/offset/1/w/280/h/180'" />
          <i v-if="data && data.object_id == currentId && loading" class="loading el-icon-loading"></i>
          <video v-if="data && data.object_id == currentId" ref="video" loop muted autoplay playsinline
            @loadedmetadata="loaddata" :poster="data.watermark_url + '?vframe/jpg/offset/1/w/280/h/180'">
            <source :src="data.watermark_url" />
          </video>
        </div>
        <p class="name">{{ data.name }}</p> -->
        <div class="video">
          <VideoCard :video="data || {}" :target="target" @pagination="pagination"
            @handleStatus="handleStatus($event, key)" />
          <p class="name">{{ data.name }}</p>
        </div>
      </div>
    </template>
    <el-empty v-else />
    <!-- 分页器 -->
    <div class="vf-music-pagination" v-if="JSON.stringify(pageQuery) !== '{}'">
      <Pagination v-if="pageQuery.total > 0" :hiddenPage="true" position="center" :limit.sync="pageQuery.page_size / 1"
        :page.sync="pageQuery.page" @pagination="pagination" :total="pageQuery.total" />
    </div>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import Pagination from '@/components/Pagination'
import VideoCard from "@/components/VfVideoCard.vue";
export default {
  components: {
    Pagination,
    VideoCard
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    pageQuery: {
      type: Object,
      default: () => { }
    },
    // 页面来源
    target: {
      type: String,
      default: ''
    }
  },
  mixins: [Buttons],
  data() {
    return {
      loading: true,
      currentId: null,
      is_collected: 0
    }
  },
  methods: {
    againGetList(type) {
      this.$emit('againGetList', type)
    },
    // status, key
    handleStatus() {
      // this.videoList[key].is_collect = status
    },
    pagination() {
      this.$emit('pagination')
    },
    /**
     * 前往视频详情页
     * @param {String} videoId 视频id
     */
    async gotoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId)

      if (!result) return
      this.$router.push(`/video/detail?videoId=${videoId}`)
    },
    loaddata() {
      this.loading = false;
    },
    /**
     * 鼠标移入视频播放
     */
    async handleMouseOver(data) {
      let res = await fetch(`${data.watermark_url}?vframe/jpg/offset/1/w/280/h/180`);
      if (res.ok) {
        let dom = document.getElementById(`coverImg_${data.object_id}`);
        dom.style.display = "none";
        this.currentId = data.object_id;
      }
    },
    /**
     * 鼠标移出视频暂停
     */
    handleMouseLeave(data) {
      let dom = document.getElementById(`coverImg_${data.object_id}`);
      dom.style.display = "block";
      let el = document.getElementsByClassName("coverimg");
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "block";
      }
      this.currentId = null;
      this.loading = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.video-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .video-card {
    width: 23.6%;
    margin-bottom: 20px;
    margin-right: 1.4%;
    cursor: pointer;

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    .video {
      width: 100%;
      height: 80%;
      border-radius: 3px;

      .name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 12px
      }

      video {
        @include size(100%, 100%);
        object-fit: fill;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
      }

      .coverimg {
        @include size(100%, 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
      }

      .loading {
        display: inline-block;
        font-size: 30px;
        position: absolute;
        top: 45%;
        left: 45%;
        color: #fff;
      }
    }

  }

  .vf-music-pagination {
    width: 100%;
    margin-top: 50px;
  }
}
</style>