<template>
  <div class="Collect-wrap container_media">
    <div class="rowShow title-row">
      <p class="card-title">我的收藏</p>
      <ul class="tab-model">
        <li :class="activeName === item.value ? 'tab active' : 'tab'" v-for="(item, idx) in tabList" :key="idx"
          @click="handleTabClick(item)">{{ item.label }}</li>
      </ul>
    </div>

    <div class="card-content">
      <div class="timeF" v-show="activeName === 'music'">
        <span class="pel">收藏时间：</span>
        <el-date-picker
          v-model="music_collect_at"
          valueFormat="yyyy-MM-dd"
          type="daterange"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getMusicListByMode(2, 'reset')"
        />
      </div>
      <musicCard target="collect" v-show="activeName === 'music'" @againGetList="againGetList" :list="musicList"
        :pageQuery="musicListQuery" @pagination="handleTabClick({ value: 'music' })"></musicCard>
      <videoCard target="collect" v-show="activeName === 'video'" @againGetList="againGetList" :list="videoList"
        :pageQuery="videoListQuery" @pagination="handleTabClick({ value: 'video' })"></videoCard>
      <effectCard target="collect" v-show="activeName === 'effect'" :list="effectList" @againGetList="againGetList"
        :pageQuery="effectListQuery" @pagination="handleTabClick({ value: 'effect' })"></effectCard>
      <pictureList target="collect" v-show="activeName === 'picture'" :fetchLoading="fetchLoading" :list="pictureList"
        @nextPage="nextPage" @againGetList="againGetList"
        :endPage="Math.ceil(pictureListQuery.total / pictureListQuery.page_size) === pictureListQuery.page"
        ref="pictureListRef" />
      <!-- 分页器 -->
      <div class="vf-music-pagination" v-if="JSON.stringify(pictureListQuery) !== '{}' && activeName === 'picture'">
        <Pagination v-if="pictureListQuery.total > 0" :hiddenPage="true" position="center"
          :limit.sync="pictureListQuery.page_size/1" :page.sync="pictureListQuery.page"
          @pagination="handleTabClick({ value: 'picture' })" :total="pictureListQuery.total" />
      </div>
    </div>
  </div>
</template>

<script>
import musicCard from '@/components/member/musicCard';
import videoCard from '@/components/member/videoCard';
import effectCard from '@/components/member/effectCard';
import pictureList from '@/views/picture/components/pictureList.vue';
import Pagination from '@/components/Pagination'
import { fetchMemberCollectList } from '@/api/member';
export default {
  components: {
    musicCard,
    videoCard,
    effectCard,
    pictureList,
    Pagination
  },
  data() {
    return {
      fetchLoading: false,
      tabList: [{
        label: '音乐',
        value: 'music'
      }, {
        label: '视频',
        value: 'video'
      }, {
        label: '音效',
        value: 'effect'
      }, {
        label: '图片',
        value: 'picture'
      }],
      activeName: 'music',
      componentName: 'musicCard',
      musicList: [],
      effectList: [],
      videoList: [],
      pictureList: [],
      music_collect_at: [],
      musicListQuery: {
        total: 0,
        page_size: 10,
        page: 1
      },
      effectListQuery: {
        total: 0,
        page_size: 16,
        page: 1
      },
      videoListQuery: {
        total: 0,
        page_size: 16,
        page: 1
      },
      pictureListQuery: {
        total: 0,
        page_size: 10,
        page: 1
      }
    }
  },
  created() {
    this.activeName = this.$route.query.activeName || 'music'
    this.handleTabClick()
  },
  methods: {
    againGetList(type) {
      this.getMusicListByMode(type)
    },
    nextPage() {
      this.pictureListQuery.page++
      this.handleTabClick({ value: 'picture' })
    },
    handleTabClick(item) {
      this.activeName = item ? item.value : 'music'
      const { activeName } = this
      const modelNames = {
        music: 2,
        effect: 3,
        video: 4,
        picture: 5
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          activeName
        }
      })
      this.getMusicListByMode(modelNames[activeName])
    },
    async getMusicListByMode(mode, type) {
      if(type) {
        this.musicListQuery.page = 1
      }
      const modesName = {
        2: 'musicList',
        3: 'effectList',
        4: 'videoList',
        5: 'pictureList'
      }
      const name = `${modesName[mode]}Query`
      if (mode === 5) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        this.fetchLoading = true
      }
      try {
        const { page, page_size } = this[name]
        const params = {
          mode,
          page,
          page_size
        }
        if(mode == 2 && this.music_collect_at && this.music_collect_at.length){
          params.create_at = {}
          params.create_at.begin = this.music_collect_at[0]
          params.create_at.end = this.music_collect_at[1]
        }
        const { data } = await fetchMemberCollectList(params)
        // 图片信息重新遍历
        if (this.activeName === 'picture') {
          data.list.forEach(item => {
            item.picture_id = item.object_id
            item.is_collect = item.is_collected
            delete item.is_collected // 防止收藏操作时提示语错误
          })
        }
        this.$set(this, [modesName[mode]], data.list)
        this[name].total = data.total
        this[name].page_size = data.page_size
        this.fetchLoading = false
        // 计算最后一个补位元素是否独占一行,独占一行的情况下进行隐藏
        setTimeout(() => {
          const containerMedia = this.$refs.pictureListRef.$refs.container_media_ref
          const after = this.$refs.pictureListRef.$refs.afterRef
          if (containerMedia?.offsetWidth <= after?.offsetWidth) {
            after.style.display = 'none';
          }
        }, 1000)
      } catch (e) {
        console.log(e)
        this.fetchLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.Collect-wrap {

  .card-title {
    margin-right: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .title-row {
    margin: 50px 0 40px;
  }

  .tab-model {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tab {
      width: 64px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 20px;
    }

    .active {
      background: rgba(229, 42, 13, 0.1);
      font-weight: 600;
      color: #E52A0D;
    }
  }
  .timeF{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span.pel{
      color: rgba(0, 0, 0, 0.5);
    }
    .search-btn {
    cursor: pointer;
    width: 60px;
    height: 32px;
    @include center-middle-flex;
    border-radius: 4px;
    border: 1px solid #E52A0D;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E52A0D;
    margin-left: 16px;

    &:hover {
      background: #E52A0D;
      color: #FFFFFF;
    }
  }
}

  /deep/.card-content {
    width: 100%;


    .el-empty {
      margin: auto;
    }
  }
}

.rowShow {
  display: flex;
  align-items: center;
}
</style>
