<template>
  <div class="videos-card" @mouseenter="handleMouseover" @mouseleave="handleMouseleave">
    <router-link :to="`/video/detail?videoId=${video.music_id}`" style="display: block;width: 100%;height: 100%;">
      <video ref="video" muted :id="videoId" :poster="video.watermark_url + '?vframe/jpg/offset/1/w/280/h/180'">
        <source v-if="videoUrl" :src="videoUrl" />
      </video>
    </router-link>
    <i v-if="loading" class="loading el-icon-loading"></i>
    <div v-if="video.isPlaying" class="operation_icons">
      <div class="video-deed-item">
        <img @click="handleVideoCollect(video)"
          :src="`${video.is_collected === 1 ? require('@/assets/svg/operation/collected.svg') : require('@/assets/image/centerVideo/collect.png')}`"
          alt="">
      </div>
      <div class="video-deed-item">
        <img src="~@/assets/image/centerVideo/share.png" alt=""
          @click="handleShare('', video.object_id, video.type || 4, video.name)">
      </div>
      <div class="video-deed-item">
        <img src="~@/assets/image/centerVideo/down.png" alt="" @click="handleDownload(video)">
      </div>
      <div class="video-deed-item">
        <img src="~@/assets/image/centerVideo/cart.png" alt="" @click="handleAddGoodsToCart(video, 4)">
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons';
export default {
  props: {
    video: {
      type: Object,
      default: () => { },
    },
    // 页面来源
    target: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadHover: true,
      shareHover: true,
      collectHover: true,
      loading: false, // 播放loading
      videoUrl: "", // 视频地址
      randomId: Math.random() * 10000, // video_id会出现重复的？？
    };
  },
  mixins: [Buttons],
  computed: {
    videoId() {
      return `video_player_${this.video.video_id}_${this.randomId}`;
    },
    videfRef() {
      return document.getElementById(this.videoId);
    },
  },
  mounted() {
    this.videfRef.addEventListener("canplaythrough", () => {
      this.loading = false;
      if (this.video.isPlaying) {
        this.videfRef.play();
      }
    });
    this.videfRef.addEventListener("play", () => {
      //播放开始执行的函数
      if (this.videfRef.readyState !== 4) {
        this.loading = true;
      }
    });
  },
  methods: {
    async handleVideoCollect(item) {
      item.is_collected = item.is_collected === 0 ? 1 : 0
      await this.handleCollect(item.object_id, item.type || 4, {
        ...item,
        type: 4,
        labels: item.label
      }, false)

      setTimeout(() => {
        this.$emit('pagination')
        this.videoUrl = ''
        // 收藏页面取消收藏后需重新拉取列表数据
        if (this.target === 'collect') {
          this.$emit('againGetList', 4)
        }
      }, 500)
    },
    // 获取播放视频地址
    async getVideoUrl() {
      this.loading = true;
      this.videoUrl = this.video.watermark_url;
    },
    // 移入视频区域
    handleMouseover() {
      this.$set(this.video, "isPlaying", true);
      if (!this.videoUrl) {
        this.getVideoUrl();
      } else {
        this.videfRef.play();
      }
    },
    // 移出视频区域
    handleMouseleave() {
      this.$set(this.video, "isPlaying", false);
      this.loading = false;
      this.videfRef.load();
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-card {
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  .loading {
    font-size: 30px;
    color: #000000;
    position: absolute;
    top: 50%;
    right: 45%;
    transform: translate(-50%, -50%);
  }

  .operation_icons {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 8px;

    .video-deed-item {
      @include size(42px, 42px);
      @include center-middle-flex;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(1px);
      margin-right: 12px;
      border-radius: 4px;

      img {
        @include size(24px, 24px);
      }
    }
  }

  video {
    @include size(100%, 100%);
    object-fit: fill;
  }
}
</style>
